import AgTable from "@/app/shared/shared-components/ag-grid/AgTableServerSide";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmSelect from "@/app/shared/shared-components/TmSelect";
import utilities from "@/app/shared/utilities";
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import _ from "lodash";

const configRepo = SharedRepositoryFactory.get('configRepository');
export default {
  name: "ConfigurationCrudView",
  data() {
    return {
      permissions: {},
      gridOptions: {},
      componentTypes: ['tmText', 'number', 'tmSelect', 'file', 'toggle', 'checkbox', 'radio'],
      modules: ['Basic', 'Website', 'Donate', 'Ecommerce', 'Quiz', 'Event'],
      configuration: {
        key: '',
        name: '',
        desc: '',
        field: [],
        module: '',
        componentType: '',
        group: ''
      }
    };
  },
  components: {
    AgTable,
    TmPopup,
    TmInput,
    TmSelect
  },
  methods: {
    createColDefs() {
      const self = this;
      return [
        {
          headerName: 'اسم الاستخدام',
          field: 'key',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الوصف',
          field: 'desc',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الغروب',
          field: 'group',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'المشروع',
          field: 'module',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: this.modules
          }
        },
        {
          headerName: 'نوع عنصر العرض',
          field: 'componentType',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: this.componentTypes
          }
        },
        {
          headerName: 'الإجراءات',
          valueGetter: (params) => {
            return params.data.id;
          },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (valueGetter, rowIndex) {
              self.delete(valueGetter);
            },
            editRecord: function (record) {
              self.edit(record);
            },
            actions: ['edit', 'delete'],
            permissions: self.permissions
          },
          sortable: false
        }
      ];
    },
    async getAll(query) {
      return configRepo.getAll(query);
    },
    create() {
      this.$refs.popup.open();
    },
    edit(config) {
      this.configuration = _.omit(config, 'value');
      this.$refs.popup.open();
    },
    async delete(value) {
      await configRepo.delete(value);
      this.gridApi.refreshServerSideStore();
    },
    async submit() {
      try {
        if (!this.configuration.id)
          await this.createConfig();
        else
          await this.updateConfig();
        this.$refs.popup.close();
      } catch (e) {
        console.log(e);
      }
    },
    async createConfig() {
      return configRepo.create(this.configuration);
    },
    async updateConfig() {
      return configRepo.update(this.configuration);
    },
    configCreated() {
      utilities.initObjectMembers(this.configuration);
      this.gridApi.refreshServerSideStore();
    },
    addField() {
      this.configuration.field.push('');
    },
    deleteField(index) {
      this.configuration.field.splice(index, 1);
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
